import './Hello.css'
function Hello(){
    return(
        <div className={'hello'}>
            <p className = {'me'}>FERMIN BUGALLO</p>
            <p className= {'port'}>PORTFOLIO</p>
        </div>
    )
}

export default Hello